@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .block {
        @apply px-6 py-4 rounded-3xl
    }

    .header {
        @apply flex justify-between items-center
    }

    button {
        @apply bg-none border-none
    }

    .chartButtons {
        @apply text-lg text-gray-800
    }

    .chartButtons button {
        @apply mr-5
    }

    .active {
        @apply text-black
    }

    .filtersForm {
        @apply mt-5 text-left ml-10 flex flex-col
    }

    .itemBlock {
        @apply flex items-center w-[100%] text-base mb-4
    }

    .itemCaption {
        @apply text-xl text-zinc-500
    }

    .itemInput {
        @apply shadow-lg outline-none rounded-xl px-3 py-1 w-[100%] text-[15px] text-gray-800
    }

    .itemCheckbox {
        transform: scale(1.4);
        margin-top: 5px
    }

    .itemBlock>* {
        @apply mr-2 font-mono
    }

    .checkboxBlock {
        @apply flex gap-4
    }

    .checkboxRect label {
        @apply text-gray-800
    }
    .checkboxRect:not(:last-child) {
        @apply mb-2
    }

    .checkboxRect input[type="checkbox"] {
        display: none;
    }

    .checkboxRect input[type="checkbox"]+label {
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 20px;
        font: 14px/20px "Open Sans", Arial, sans-serif;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .checkboxRect input[type="checkbox"]:hover+label:hover {
        color: rgb(24,164,225);
    }

    .checkboxRect input[type="checkbox"]:hover+label:before {
        background: #18a4e1;
        box-shadow: inset 0px 0px 0px 2px #f7f2f2;
    }

    .checkboxRect input[type="checkbox"]+label:last-child {
        margin-bottom: 0;
    }

    .checkboxRect input[type="checkbox"]+label:before {
        content: "";
        display: block;
        width: 1.4em;
        height: 1.4em;
        border: 1px solid #343a3f;
        border-radius: 0.2em;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: all 0.2s, background 0.2s ease-in-out;
        transition: all 0.2s, background 0.2s ease-in-out;
        background: #f3f3f3;
    }

    .checkboxRect input[type="checkbox"]:checked+label:before {
        width: 1.3em;
        height: 1.3em;
        border-radius: 0.2em;
        border: 2px solid #fff;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        background: #18a4e1;
        box-shadow: 0 0 0 1px #000;
    }

    .startBtn {
        @apply px-3 py-1 border-solid border-[2px] border-gray-800 text-gray-800 shadow-lg w-[100%] transition-all 
    }
    .startBtn:hover {
        @apply bg-[#7dcff1]
    }
    .startBtn:active {
        @apply bg-[#18a4e1] text-white
    }
    .startBtn:disabled {
        @apply bg-gray-200 opacity-70 text-gray-800
    }
}