@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply px-[15px] py-1 my-1 rounded-lg w-full text-[18px] flex hover:bg-zinc-200
    }

    .btn>* {
        @apply mr-2
    }

}