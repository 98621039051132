@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .itemsBlock {
        @apply w-[100%] h-[100%] bg-white border-[#18a4e1] border-2 text-white overflow-auto
    }

    .itemsBlock>*:not(:last-child) {
        @apply mb-3
    }

    .pagination {
        @apply cursor-pointer text-[20px]
    }

    .selectedPage {
        @apply font-bold
    }

}

/* #e79999 - red */
/* #7bba7b - green */