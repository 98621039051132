@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .objectItem {
        @apply flex items-center h-[200px] p-3 rounded-2xl text-gray-800 font-mono
    }
    .deletedItem {
        @apply bg-[#f8c4c4]
    }
    .newItem {
        @apply bg-[#bff0bf]
    }
    .commonItem {
        @apply bg-zinc-200
    }
    .objectItem > *:not(:last-child) {
        @apply mr-3
    }
    .imageBlock {
        @apply w-[18%] max-w-[18%];
        object-fit: cover;
    }
    .mainInfoBlock {
        @apply flex flex-col w-[70%] max-w-[70%] h-full
    }
    .mainInfoBlock > *:not(:last-child) {
        @apply mb-2
    }
    .blueBG {
        @apply bg-[#ffffff] rounded-2xl px-3 py-1
    }
    .priceBlock {
        @apply mt-2
    }
    .priceText {
        @apply text-lg font-semibold font-mono
    }
    .descriptionBlock {
        @apply h-[100%] overflow-auto 
    }
    .iconsValue {
        @apply flex items-center
    }
    .value {
        @apply text-[22px]
    }
}
/* #e79999 - red */
/* #7bba7b - green */